import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const CompContentReasons = () => {
  const [showDetails, setShowDetails] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ])

  const toggleVisibility = (e, ii) => {
    const showDetailsArr = [...showDetails]
    showDetailsArr[ii] = !showDetails[ii]
    setShowDetails(showDetailsArr)
  }

  const data = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "reason1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img2: file(relativePath: { eq: "reason2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img3: file(relativePath: { eq: "reason3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img4: file(relativePath: { eq: "reason4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img5: file(relativePath: { eq: "reason5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img6: file(relativePath: { eq: "reason6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img7: file(relativePath: { eq: "reason7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img8: file(relativePath: { eq: "reason8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const reasonsData = [
    {
      id: 1,
      title: "人生を変えたい！出会いと趣味の為に",
      short_body:
        "実はとても簡単になったDJ。適切なレッスンを順序よく行えば誰でも出来ることが出来る物です。多くの人ので出会いがあり、自分を変えることが出来る趣味であると断言出来ます。",
      img: data.img1.childImageSharp.fluid,
      color1: "#ffcd03",
      color2: "#ffbe02",
      color3: "#fff7d5",
      reasons: [
        "成長した自分に出会える",
        "笑顔で居れる時間が増える",
        "音楽の話が出来る友達が増える",
        "人生のパートナーが見つかる",
        "新しいビジネスのきっかけになる",
      ],
      body2:
        "当アカデミーでは提携CLUB、音楽フェス、交流会、セミナー勉強会など、豊富な体験と出会いを提供している音楽スクールです。初めてDJ文化に触れる方々にもっとも適した環境を用意しております。",
    },
    {
      id: 2,
      title: "本気でDJを職業にしたい！",
      short_body:
        "TryHard DJ Academyの一番の魅力が現場直結！私達は日本最大のCLUBグループとしてあなたの夢である”DJを職業にしたい！”を最短ルートで導く事が出来ます。",
      img: data.img2.childImageSharp.fluid,
      color1: "#e60039",
      color2: "#de0028",
      color3: "#ffd2dd",
      reasons: [
        "実際に卒業生がDJを職業にしています。",
        "当社が経営運営するCLUBとフェスがあります。",
        "専門学校と同等のDJ技術と音楽制作が学べます。",
        "卒業してからもサポートを受けられます。",
        "世界的なアーティストと共演出来ます。",
      ],
      body2:
        "当アカデミーで学んだ技術を使って実際のCLUBに立ってDJを仕事に出来る環境があります。DJの新しい価値観と新しい可能性を”あなたが切り開く”そんな未来を想像して突き進んで欲しいです。",
    },
    {
      id: 3,
      title: "お子様の将来の役に立てるために",
      short_body:
        "お子様でも手軽にプレイする事が出来ます。 DJ・DTMは最新のテクノロジーに触れられる音楽ツールでもあります。",
      img: data.img3.childImageSharp.fluid,
      color1: "#ff5b5b",
      color2: "#ff4040",
      color3: "#ffd8d8",
      reasons: [
        "手軽に音楽を始められる。",
        "操作感がゲーム機のような感覚。",
        "出会いも多いので人間力が養われる。",
        "パソコンの操作に慣れることが出来る。",
        "AI（人口知能）などにも手軽に触れられる。",
      ],
      body2:
        "ゲーム感覚で楽しく手軽にDJプレイが出来るようになります。昨今プログラミングの授業が子供達に普及していますが、未来の音楽ツールは間違いなくDJであり、音楽制作はDTM（パソコンでの音楽制作）であるのは間違いないです。",
    },
    {
      id: 4,
      title: "シニア世代の新しい出会い作りのために",
      short_body:
        '音楽が好き"と言う共通の趣味を持っている同世代はもちろん、 普段交流が少ないその他の世代との交流も大変盛んです。',
      img: data.img4.childImageSharp.fluid,
      color1: "#01960f",
      color2: "#01760a",
      color3: "#d3ffd7",
      reasons: [
        "新しい出会いを作りやすい。",
        "同じ趣味の仲間と繋がれる。",
        "手軽に新しい趣味が出来る。",
        "思い出が作れる。",
        "DJが出来て楽しい。",
      ],
      body1:
        "誰でも気軽にDJができるようになります。音楽と言う共通の趣味を持った人との出会いが沢山あります。",
      body2:
        "50代～70代の生徒さんが近年非常に増えております。あの頃憧れていたDJに成りたいという願望の実現に最適な環境を用意しております。",
    },
    {
      id: 5,
      title: "著名人の新たなチャネル作りのために",
      short_body:
        "芸能人・モデル・インフルエンサー・YouTuber・ブロガーなどの著名人の方のブランディングサポートが可能です。",
      img: data.img5.childImageSharp.fluid,
      color1: "#954dc3",
      color2: "#7536b1",
      color3: "#e4c8f6",
      reasons: [
        "新しいファンの獲得が出来ます。",
        "ファンとの交流の場に役立ちます。",
        "出演場所の営業サポートが出来ます。",
        "新しい影響力を作れます。",
        "手軽に音楽活動を始められます。",
      ],
      body1: "",
      body2:
        "DJ YAMATOMAYA さんが当校の成功事例として全国のフェスやCLUB、企業PARTYやレセプションPARTYにDJとして出演し大活躍されています。",
    },
    {
      id: 6,
      title: "海外で人気者になるために",
      short_body:
        "海外留学や旅行に行くと個性がある人の周りには自然と人が集まるものです。そんな時DJが出来ると人気者になり人生のチャンスに繋がります。",
      img: data.img6.childImageSharp.fluid,
      color1: "#3885e5",
      color2: "#2760dd",
      color3: "#cfe4ff",
      reasons: [
        "海外で一目置かれます。",
        "海外行った際の武器になります。",
        "言語を超えたコミュニケーションが出来ます。",
        "友達が沢山出来ます。",
        "仕事に繋がります。",
      ],
      body1: "",
      body2:
        "海外ではDJ文化が根付いていて、皆パーティーを毎週楽しみます。そんなシーンで人気者になれるチャンスがあるのは、DJで間違いありません。願望の実現に最適な環境を用意しております。",
    },
    {
      id: 7,
      title: "海外でアーティスト活動するために",
      short_body:
        "海外で活動出来るレベルの高いDJを目指して頂けます。機材はPioneer世界標準機を完備し最先端のミックス、エフェクト技術の共有。海外を意識したグローバルな視野を持ったDJスクールです。",
      img: data.img7.childImageSharp.fluid,
      color1: "#ff7800",
      color2: "#ff5400",
      color3: "#ffdfc3",
      reasons: [
        "海外で通用するためのカリキュラムがあります。",
        "海外とのパイプ役になれます。",
        "楽曲制作では海外の主要レーベルのエンジニアから日本で唯一サポートを受けています。",
        "当校はMUSIC CIRCUSに出演実績があります。",
        "自己実現出来るまでサポートする体制があります。",
      ],
      body1: "",
      body2:
        '海外で活動している卒業生・関係者が既におります。"アーティスト・サポートシステム"があり、あなた の夢を実現するためのプロデュースを致します。',
    },
    {
      id: 8,
      title: "他校から転校を考えている方のために",
      short_body:
        "他校でのレッスン受講で思ったような成果が上がらない方や、更に高みを目指したい方の目標の実現にお力添えさせて頂きます。",
      img: data.img8.childImageSharp.fluid,
      color1: "#34c4bc",
      color2: "#24b2a8",
      color3: "#c1f3f0",
      reasons: [
        "別の角度から勉強する事が出来る。",
        "知識の幅がかなり広がる。",
        "「技術力・集客力・演出力」高いレベルのカリキュラムがあります。",
        "自己実現出来るまでサポートする体制が整っています。",
        "国内外で結果を出している関係者に繋がりがあります。",
      ],
      body1: "",
      body2:
        "他校から当校に転入された方は、入会金が免除されますので入会の際に担当者にお伝え下さいませ。※免除対応は入会時のみとさせて頂きます。予めご了承下さいませ。",
    },
  ]

  const reasons = reasonsData.map((i, ii) => (
    <Reason
      key={i.id}
      color1={i.color1}
      color2={i.color2}
      color3={i.color3}
      className={showDetails[ii] ? `is-open` : ``}
    >
      <h4 className="title">{i.title}</h4>
      <div className="image-wrapper">
        <Img fluid={i.img} />
      </div>
      <div className="body">
        <p className="short-body">{i.short_body}</p>
        <div className="details">
          {i.body1 && <p className="expanded-body-1">{i.body1}</p>}
          {i.reasons.map(j => (
            <p key={j} className="reason">
              <span>{j}</span>
            </p>
          ))}
          <p className="expanded-body-1">{i.body2}</p>
        </div>
        <div className="expand-btn-wrapper">
          <span className="expand-btn" onClick={e => toggleVisibility(e, ii)}>
            {showDetails[ii] ? "閉じる" : "詳しく見る"}
          </span>
        </div>
      </div>
    </Reason>
  ))

  return <ReasonsContainer>{reasons}</ReasonsContainer>
}

export default CompContentReasons

const ReasonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
`

const Reason = styled.div`
  --color1: ${({ color1 }) => color1};
  --color2: ${({ color2 }) => color2};
  --color3: ${({ color3 }) => color3};

  position: relative;
  width: calc(50% - 0.5rem);
  margin: 0 0 3rem;
  border: 2px solid var(--color1);
  border-radius: 1rem;

  @media (max-width: 650px) {
    width: 100%;
  }

  .title {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 0rem;
    width: calc(100% - 2rem);
    margin: 0;
    padding: 0.5rem 1rem;
    border-radius: 2.25rem;
    color: white;
    text-align: center;
    line-height: 1;
    font-weight: 700;
    font-size: 1.25rem;
    background: repeating-linear-gradient(
      135deg,
      var(--color1) 0,
      var(--color1) 5px,
      var(--color2) 7px,
      var(--color2) 12px,
      var(--color1) 12px
    );
    transform: translate(-50%, -50%);
  }

  .image-wrapper {
    position: relative;
    z-index: 1;
    border-radius: 0.9rem 0.9rem 0 0;
    overflow: hidden;
  }

  .body {
    padding: 1rem;
    font-size: 0.875rem;
  }

  .short-body {
    margin: 0 0 1rem;
  }

  .reason {
    margin: 0 0 1rem;

    span {
      display: inline-block;
      padding: 0.25em;
      background: var(--color3);
      font-size: 1rem;
      font-weight: 900;
    }
  }

  .details {
    /* display: none; */
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.25s;
  }

  &.is-open .details {
    /* display: block; */
    max-height: 600px;
  }

  .expand-btn-wrapper {
    position: relative;
    width: 150px;
    margin: 1rem auto 0;
    border-radius: 2em;

    &::before {
      position: absolute;
      content: "";
      top: 0.25rem;
      left: 0.25rem;
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 2em;
      background: repeating-linear-gradient(
        45deg,
        var(--color1) 0,
        var(--color1) 2px,
        transparent 2px,
        transparent 4px
      );
    }
  }

  .expand-btn {
    position: relative;
    z-index: 2;
    display: block;
    width: 150px;
    padding: 0.5em 1em;
    border: 0;
    border-radius: 2em;
    color: white;
    background: var(--color1);
    text-align: center;
    line-height: 1;
    text-decoration: none;

    &::before {
      display: inline-block;
      content: "+";
      margin-right: 0.5em;
      transform: rotate(0deg);
      transition: transform 0.25s;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &.is-open .expand-btn::before {
    transform: rotate(45deg);
  }
`
