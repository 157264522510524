import React from "react"
import styled from "styled-components"
import bgYoutubeBanner from "../../../images/bg-youtube-min.jpg"
import BtnMore from "../../btnMore"

const CompContentYoutubeBanner = () => {
  return (
    <YoutubeBanner>
      <h3>Youtube</h3>
      <BtnMore link="https://www.youtube.com/" external={true}>
        YouTubeチャンネルへ
      </BtnMore>
    </YoutubeBanner>
  )
}

export default CompContentYoutubeBanner

const YoutubeBanner = styled.div`
  display: grid;
  grid-template-columns: 2rem 1fr 1fr 2rem;
  margin: 3rem 0;
  padding: 3rem 0;
  background: url(${bgYoutubeBanner}) no-repeat center/cover;

  @media (max-width: 600px) {
    grid-template-column: auto;
    grid-template-rows: 2rem auto auto 2rem;
    /* background: url(${bgYoutubeBanner}) no-repeat left center/cover; */
  }

  h3 {
    grid-column: 2 / 3;
    justify-self: left;
    align-self: center;
    color: white;
    font-size: 2rem;
    text-shadow: 0 0 0.5em rgba(0, 0, 0, 0.7);
    font-family: sans-serif;

    @media (max-width: 600px) {
      grid-column: 1 / -1;
      grid-row: 2 / 3;
      justify-self: center;
      align-self: center;
    }
  }

  a {
    grid-column: 3 / 4;
    justify-self: right;
    align-self: center;
    font-family: sans-serif;

    @media (max-width: 600px) {
      grid-column: 1 / -1;
      grid-row: 3 / 4;
      justify-self: center;
      align-self: center;
    }
  }
`
