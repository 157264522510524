import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import UnstyledImg from "gatsby-image"

import bgClubs from "../../../images/bg-clubs-min.jpg"

const CompContentClubs = () => {
  const data = useStaticQuery(graphql`
    query {
      imgMap: file(relativePath: { eq: "map-japan.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgClubs: file(relativePath: { eq: "nightclubs.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Clubs>
      <h2>
        TryHard DJ Academy
        <br />
        関連ナイトクラブ
      </h2>
      <div className="inner">
        <Img fluid={data.imgMap.childImageSharp.fluid} alt="日本の地図" />
        <Img
          fluid={data.imgClubs.childImageSharp.fluid}
          alt="関連ナイトクラブ"
        />
      </div>
    </Clubs>
  )
}

export default CompContentClubs

const Clubs = styled.div`
  padding: 2rem 0 4rem;
  background: url(${bgClubs}) no-repeat center/cover;
  color: white;

  .inner {
    width: calc(100% - 3rem);
    max-width: 1000px;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
`

const Img = styled(UnstyledImg)`
  width: calc(50% - 0.5rem);

  @media (max-width: 600px) {
    width: 100%;

    &:last-child {
      margin: 2rem 0 0;
    }
  }
`
