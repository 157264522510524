import React from "react"
import styled from "styled-components"

const CTAButtons = ({ ...props }) => {
  return (
    <ButtonContainer {...props}>
      <ContactButton href="/contact">
        お問い合わせ <span>&rang;</span>
      </ContactButton>
      <FreeLessonButton href="/lesson">
        無料体験レッスン <span>&rang;</span>
      </FreeLessonButton>
    </ButtonContainer>
  )
}

export default CTAButtons

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;

  @media (max-width: 616px) {
    justify-content: center;
  }
`

const ContactButton = styled.a`
  width: calc(50% - 1.25rem);
  margin: 1rem 0;
  padding: 0.5em 1em;
  border-radius: 0.25em;
  text-align: center;
  text-decoration: none;
  transition: background 0.25s, color 0.25s;

  color: ${({ theme }) => theme.dark};
  background: ${({ theme }) => theme.yellow};
  box-shadow: 0.25em 0.25em 0.5em rgba(168, 135, 0, 0.6);

  &:hover {
    background: ${({ theme }) => theme.dark};
    color: white;
  }

  @media (max-width: 616px) {
    width: 75%;
    min-width: 280px;
    margin: 0.5rem 0;
  }

  span {
    margin: 0 -1em 0 0.5em;
  }
`

const FreeLessonButton = styled(ContactButton)`
  color: white;
  background: ${({ theme }) => theme.red};
  box-shadow: 0.25em 0.25em 0.5em rgba(154, 0, 38, 0.6);
`
