import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import UnstyledImg from "gatsby-image"
import SEO from "../components/seo"
import Wrap from "../components/wrap"
import HomeHeader from "../components/content/home/homeHeader"
import FreeLessonBanner from "../components/content/home/freeLessonBanner"
import ComparisonTable from "../components/content/home/comparisonTable"
import Section from "../components/section"
import Video from "../components/video"
import CTAButtons from "../components/ctaButtons"
import Merit from "../components/content/home/merit"
import Reasons from "../components/content/home/reasons"
import imgCtabanner from "../images/cta-banner.jpg"
import Plans from "../components/content/home/plans"
import Courses from "../components/content/home/courses"
import Instructors from "../components/content/home/instructors"
import Testimonials from "../components/content/home/testimonials"
import Record from "../components/content/home/record"
import Clubs from "../components/content/home/clubs"
import MusicCircus from "../components/content/home/musicCircus"
import ThreeFaiths from "../components/content/home/threeFaiths"
import YoutubeBanner from "../components/content/home/youtubeBanner"
import DJ1 from '../components/content/home/dj1'

const PageIndex = ({ data }) => {
  return (
    <>
      <SEO />
      <HomeHeader />
      <FreeLessonBanner />
      <ComparisonTable />
      <Wrap>
        <Section>
          <h2>アカデミーの雰囲気</h2>
          <Gallery>
            <Img
              fluid={data.gallery1.childImageSharp.fluid}
              alt="アカデミーの雰囲気"
            />
            <Img
              fluid={data.gallery2.childImageSharp.fluid}
              alt="アカデミーの雰囲気"
            />
            <Img
              fluid={data.gallery3.childImageSharp.fluid}
              alt="アカデミーの雰囲気"
            />
            <Img
              fluid={data.gallery4.childImageSharp.fluid}
              alt="アカデミーの雰囲気"
            />
            <Img
              fluid={data.gallery5.childImageSharp.fluid}
              alt="アカデミーの雰囲気"
            />
          </Gallery>
          <p>
            当DJアカデミーは開校7年目、関西最大のDJ教育の場としてこれまでに1000名以上の方にDJや音楽の素晴らしさを伝えて来ました。
            <br />
            趣味の一つとしてDJを楽しまれる方から実際にプロとしてDJを仕事にしたい方まで幅広い年齢層の方々に通って頂いております。
            <br />
            母体であるTryHard
            JAPANは大阪の新しいエンターティメントを生み出す企業として”MUSIC
            CIRCUS”などの大規模なフェスティバルや
            大阪、神戸、京都、名古屋、東京でナイトクラブを運営する事業者であります。2020年7月より新体制を発足し関東最大のDJスクールである
            Ⅲ FAITHS DJ
            SCHOOLと正式に業務提携を行い運営メンバーを強化。”専門学校と同等のDJ教育を行える場”として運営を行っております。
          </p>
          <Video id="qJbM_K9LhDk" title="TryHard DJ Academy CM | 2020ver." />
          <CTAButtons style={{ margin: "3rem auto" }} />
        </Section>
        <Section>
          <h2>TryHard DJアカデミーを選ぶメリット</h2>
          <Merit />
          <H3>
            “大好きな音楽”をDJとして今度はあなたが流す側に。
            <br />
            あなたの目的にあった受講スタイルをご案内致します。
          </H3>
          <Reasons />
        </Section>
      </Wrap>
      <CTABanner>
        <CTAButtons />
      </CTABanner>
      <Wrap>
        <Section>
          <h2>コース・料金のご案内</h2>
          <Plans />
        </Section>
        <Section>
          <h2>特設コース</h2>
          <Courses />
        </Section>
      </Wrap>
      <Section>
        <Instructors />
      </Section>
      <Wrap>
        <Section>
          <Testimonials />
        </Section>
      </Wrap>
      <Section>
        <DJ1 />
      </Section>
      <Section>
        <Record />
      </Section>
      <Section>
        <Clubs />
      </Section>
      <Wrap>
        <Section>
          <MusicCircus />
          <ThreeFaiths />
        </Section>
        <Section>
          <CTAButtons />
        </Section>
        <Section>
          <YoutubeBanner />
        </Section>
      </Wrap>
    </>
  )
}

export default PageIndex

export const pageQuery = graphql`
  query {
    gallery1: file(relativePath: { eq: "gallery1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gallery2: file(relativePath: { eq: "gallery2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gallery3: file(relativePath: { eq: "gallery3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gallery4: file(relativePath: { eq: "gallery4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gallery5: file(relativePath: { eq: "gallery5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Gallery = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
`

const Img = styled(UnstyledImg)`
  width: calc(50% - 0.5rem);
  margin: 0 0 1rem;

  &:nth-child(1) {
    width: 100%;
  }
`

const H3 = styled.h3`
  margin: 4rem 0 2rem;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
`

const CTABanner = styled.div`
  padding: 5rem 0;
  background: url(${imgCtabanner}) no-repeat center/cover;
`
