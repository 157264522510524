import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import UnstyledImg from "gatsby-image"
import styled from "styled-components"

const CompContentMerit = () => {
  const data = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "merit1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img2: file(relativePath: { eq: "merit2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img3: file(relativePath: { eq: "merit3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container>
      <div>
        <h3>国内最大級のDJアカデミー</h3>
        <Img fluid={data.img1.childImageSharp.fluid} />
        <p>
          年齢層も3歳から60代の方まで幅広く、学生、社会人、タレント、モデル、芸能人の方など多数の生徒様が受講されています！
        </p>
      </div>

      <div>
        <h3>国内最大級の講師陣</h3>
        <Img fluid={data.img2.childImageSharp.fluid} />
        <p>
          クラブ・フェスで活躍しているプロDJから、作曲・リリースをしている講師陣など30名以上在籍！各ジャンルに特化した幅広いレッスンが可能です。
        </p>
      </div>

      <div>
        <h3>国内最大級のデビュー率</h3>
        <Img fluid={data.img3.childImageSharp.fluid} />
        <p>
          関西主要クラブや、お昼のイベントを中心に、なんとDJデビュー率90％以上！実際にプロになった生徒様も多数いらっしゃいます！！
        </p>
      </div>
    </Container>
  )
}

export default CompContentMerit

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  > div {
    width: calc((100% - 2rem) / 3);

    @media (max-width: 700px) {
      width: 100%;
      margin-bottom: 2rem;
    }
  }

  h3 {
    margin: 0;
    padding: 0.75rem 0.5rem;
    border-radius: 0.5rem 0.5rem 0 0;
    background: ${({ theme }) => theme.yellow};
    text-align: center;
    font-size: 1.125rem;
  }

  p {
    margin: 0;
    padding: 0.75rem;
    border-radius: 0 0 0.5rem 0.5rem;
    background: #f2f2f2;
    font-size: 0.875rem;
  }
`

const Img = styled(UnstyledImg)``
