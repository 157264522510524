import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const CompContentRecord = () => {
  const data = useStaticQuery(graphql`
    query {
      imgEvent1: file(relativePath: { eq: "record-event-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgEvent2: file(relativePath: { eq: "record-event-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgEvent3: file(relativePath: { eq: "record-event-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgEvent4: file(relativePath: { eq: "record-event-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgEvent5: file(relativePath: { eq: "record-event-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgEvent6: file(relativePath: { eq: "record-event-6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgEvent7: file(relativePath: { eq: "record-event-7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgEvent8: file(relativePath: { eq: "record-event-8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgEvent9: file(relativePath: { eq: "record-event-9.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgEvent10: file(relativePath: { eq: "record-event-10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgEvent11: file(relativePath: { eq: "record-event-11.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgEvent12: file(relativePath: { eq: "record-event-12.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgEvent13: file(relativePath: { eq: "record-event-13.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgEvent14: file(relativePath: { eq: "record-event-14.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgEvent15: file(relativePath: { eq: "record-event-15.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgEvent16: file(relativePath: { eq: "record-event-16.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgArtist1: file(relativePath: { eq: "record-artist-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgArtist2: file(relativePath: { eq: "record-artist-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgArtist3: file(relativePath: { eq: "record-artist-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgArtist4: file(relativePath: { eq: "record-artist-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgArtist5: file(relativePath: { eq: "record-artist-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgArtist6: file(relativePath: { eq: "record-artist-6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const eventData = [
    { img: data.imgEvent1.childImageSharp.fluid },
    { img: data.imgEvent2.childImageSharp.fluid },
    { img: data.imgEvent3.childImageSharp.fluid },
    { img: data.imgEvent4.childImageSharp.fluid },
    { img: data.imgEvent5.childImageSharp.fluid },
    { img: data.imgEvent6.childImageSharp.fluid },
    { img: data.imgEvent7.childImageSharp.fluid },
    { img: data.imgEvent8.childImageSharp.fluid },
    { img: data.imgEvent9.childImageSharp.fluid },
    { img: data.imgEvent10.childImageSharp.fluid },
    { img: data.imgEvent11.childImageSharp.fluid },
    { img: data.imgEvent12.childImageSharp.fluid },
    { img: data.imgEvent13.childImageSharp.fluid },
    { img: data.imgEvent14.childImageSharp.fluid },
    { img: data.imgEvent15.childImageSharp.fluid },
    { img: data.imgEvent16.childImageSharp.fluid },
  ]

  const events = eventData.map((i, ii) => (
    <li key={ii}>
      <Img fluid={i.img} alt="イベント出演実績" />
    </li>
  ))

  const artistData = [
    { img: data.imgArtist1.childImageSharp.fluid },
    { img: data.imgArtist2.childImageSharp.fluid },
    { img: data.imgArtist3.childImageSharp.fluid },
    { img: data.imgArtist4.childImageSharp.fluid },
    { img: data.imgArtist5.childImageSharp.fluid },
    { img: data.imgArtist6.childImageSharp.fluid },
  ]

  const artists = artistData.map((i, ii) => (
    <li key={ii}>
      <Img fluid={i.img} alt="アーティスト実績" />
    </li>
  ))

  return (
    <Record>
      <div className="inner">
        <h2>イベント出演実績</h2>
        <div className="events">
          <ul>
            {events}
            <li>他にも多数の実績！</li>
          </ul>
        </div>
        <h2>アーティスト実績</h2>
        <div className="artists">
          <ul>{artists}</ul>
        </div>
      </div>
    </Record>
  )
}

export default CompContentRecord

const Record = styled.div`
  padding: 2rem 0;
  background: repeating-linear-gradient(
    135deg,
    white 0px,
    white 2px,
    #e7e7e7 3px,
    #e7e7e7 5px,
    white 6px
  );

  h2 {
    &::first-child {
      margin-top: 0;
    }
  }

  .inner {
    width: calc(100% - 1rem);
    max-width: 1000px;
    margin: 0 auto;
  }

  .events {
    margin: 2rem 0 4rem;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      /* display: flex;
      justify-content: flex-start;
      align-items: flex-center;
      flex-wrap: wrap; */

      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: auto;
      grid-gap: 1rem;

      @media (max-width: 768px) {
        grid-template-columns: repeat(5, 1fr);
      }

      @media (max-width: 600px) {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 0.5rem;
      }

      @media (max-width: 414px) {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    li {
      &:last-of-type {
        grid-column: span 2;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1em 0;
        border: 2px solid white;
        background: #33322c;
        color: white;
        font-size: 1.5rem;
        font-weight: 700;

        @media (max-width: 768px) {
          grid-column: span 4;
        }

        @media (max-width: 600px) {
          font-size: 1.25rem;
        }

        @media (max-width: 414px) {
          grid-column: span 2;
        }
      }
    }

    img {
      max-width: 100%;
    }
  }

  .artists {
    margin: 0 0 2rem;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    li {
      width: calc((100% - 3rem) / 3);
      margin: 0 0 1.5rem;

      @media (max-width: 768px) {
        width: calc((100% - 2rem) / 3);
        margin: 0 0 1rem;
      }

      @media (max-width: 600px) {
        width: calc((100% - 1rem) / 3);
        margin: 0 0 0.5rem;
      }
    }

    img {
      max-width: 100%;
    }
  }
`
