import React from "react"
import styled from "styled-components"
import imgMusicCircus from "../../../images/music-circus.jpg"

const CompContentMusicCircus = () => {
  return (
    <MusicCircus>
      <img src={imgMusicCircus} alt="MUSIC CIRCUS" />
      <div className="content">
        <h3>
          <span>MUSIC CIRCUS</span>
        </h3>
        <p>
          トライハードの核となる事業であり、「トライハード＝MUSIC
          CIRCUS」とも言える大型の音楽フェスティバル。2014年の誕生から着実に規模を拡大させ、音楽を軸に誰もが楽しめるエンターテイメントを追及、一過性ではない、継続可能な地域振興と文化向上に努め、大阪から世界へ発信される国際的な広がりを展望しております。
        </p>
      </div>
    </MusicCircus>
  )
}

export default CompContentMusicCircus

const MusicCircus = styled.div`
  margin: 3rem 0;

  img {
    max-width: 100%;
  }

  .content {
    padding: 1rem 1.5rem;
    background: #f2f2f2;
  }

  h3 {
    margin: 0%;

    span {
      display: inline-block;
      padding: 0.1em 1em;
      background: ${({ theme }) => theme.yellow};
    }
  }

  p {
    margin: 1rem 0 0;
    line-height: 1.75;
  }
`
