import React from "react"
import { Link as UnstyledLink, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const CompContentCourses = () => {
  const data = useStaticQuery(graphql`
    query {
      imgZero: file(relativePath: { eq: "course-banner-zero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgDtm: file(relativePath: { eq: "course-banner-dtm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgDj: file(relativePath: { eq: "course-banner-dj.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgKids: file(relativePath: { eq: "course-banner-kids.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgVj: file(relativePath: { eq: "course-banner-vj.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgMc: file(relativePath: { eq: "course-banner-mc.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgCourse: file(relativePath: { eq: "courses.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <Courses>
        <li className="course course-zero">
          <LinkExternal href="https://dj-zero.com/" target="_blank" rel="noopener noreferrer">
            <div className="name">
              <span>DJ ZERO</span>
            </div>
            <Img fluid={data.imgZero.childImageSharp.fluid} />
          </LinkExternal>
        </li>
        <li className="course course-dtm">
          <Link to="/course/dtm">
            <div className="name">
              <span>DTMコース</span>
            </div>
            <Img fluid={data.imgDtm.childImageSharp.fluid} />
          </Link>
        </li>
        <li className="course course-dj">
          <Link to="/course/dj">
            <div className="name">
              <span>DJコース</span>
            </div>
            <Img fluid={data.imgDj.childImageSharp.fluid} />
          </Link>
        </li>
        <li className="course course-kids">
          <Link to="/courses">
            <div className="name">
              <span>KIDSコース</span>
            </div>
            <Img fluid={data.imgKids.childImageSharp.fluid} />
          </Link>
        </li>
        <li className="course course-vj">
          <Link to="/courses">
            <div className="name">
              <span>VJコース</span>
            </div>
            <Img fluid={data.imgVj.childImageSharp.fluid} />
          </Link>
        </li>
        <li className="course course-mc">
          <Link to="/courses">
            <div className="name">
              <span>MCコース</span>
            </div>
            <Img fluid={data.imgMc.childImageSharp.fluid} />
          </Link>
        </li>
      </Courses>
      <Feature>
        <div className="feature-body">
          <h3>現代を代表する音楽ツールそれが"DJ"</h3>
          <p>
            もしもあなたが音楽が大好きならば現代で手に取るべき楽器（音楽ツール）はDJだと強く断言出来ます。一度その世界へ飛び込めば、たった独りでCLUBやフェス、BAR、カフェ、ラウンジetc…で音楽を演奏することが出来る魔法のアイテムと言えます。しかもDJは正しい指導を受ければ、現代では誰でも習得可能な音楽ツールです。トライハードDJアカデミーでは全くの初心者の方が基本テクニックをマスター出来る完璧なカリキュラムを用意し無駄の無いレッスンを約束します。その気になれば1ヶ月や3ヶ月で憧れのDJになるのも夢ではありません。また日本を代表する人気CLUBへの出演はもちろん、他種多様なステージを卒業後もご提供し生徒さんのDJライフをサポートすることを約束致します。
          </p>
        </div>
        <Illustration fluid={data.imgCourse.childImageSharp.fluid} />
      </Feature>
    </>
  )
}

export default CompContentCourses

const Courses = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  list-style: none;
  margin: 2rem 0;
  padding: 0;

  .course {
    width: calc((100% - 1rem) / 2);
    margin: 0 0 1rem;

    &.course-zero {
      width: 100%;
    }
  }

  .name {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
    transition: opacity 0.25s;

    span {
      position: relative;
      margin: 0 0 2rem;

      &::before {
        position: absolute;
        left: 50%;
        top: calc(100% + 1rem);
        content: "";
        display: inline-block;
        width: 250px;
        height: 1px;
        background: white;
        transform: translateX(-50%);
      }
    }

    &::after {
      content: "MORE";
    }
  }

  a:hover .name {
    @media (min-width: 769px) {
      opacity: 1;
    }
  }
`

const Link = styled(UnstyledLink)`
  position: relative;
  display: block;
  color: white;
  text-decoration: none;
`

const LinkExternal = styled.a`
  position: relative;
  display: block;
  color: white;
  text-decoration: none;
`

const Feature = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: calc(50% - 100px) 100px 50%;
  grid-template-rows: auto;

  @media (max-width: 768px) {
    grid-template-columns: auto;
    grid-template-rows: auto 100px auto;
  }

  @media (max-width: 475px) {
    grid-template-rows: auto 50px auto;
  }

  .feature-body {
    position: relative;
    z-index: 2;
    grid-column: 1 / 3;
    grid-row: 1 / -1;
    align-self: center;
    padding: 1rem;
    border: 2px solid ${({ theme }) => theme.yellow};
    background: white;

    @media (max-width: 768px) {
      grid-column: 1 / -1;
      grid-row: 2 / -1;
      width: calc(100% - 4rem);
      justify-self: center;
    }

    @media (max-width: 375px) {
      width: calc(100% - 2rem);
    }

    h3 {
      margin: 0 0 1rem;
    }

    p {
      margin: 0;
      font-size: 0.875rem;
    }
  }
`

const Illustration = styled(Img)`
  position: relative;
  z-index: 1;
  grid-column: 2 / -1;
  grid-row: 1 / -1;

  @media (max-width: 768px) {
    grid-column: 1 / -1;
    grid-row: 1 / 3;
  }
`
