import React from "react"
import styled from "styled-components"
import { hideVisually } from "polished"
import imgBackground from "../../../images/bg-header-min.jpg"
import imgLogo from "../../../images/logo-homeheader.png"
import imgPr1 from "../../../images/pr-lowprice.png"
import imgPr2 from "../../../images/pr-result.png"
import imgPr3 from "../../../images/pr-support.png"

const CompContentHomeHeader = () => {
  return (
    <Header>
      <h1>
        <span>TryHard DJ Academy</span>
      </h1>
      <p>国内最大のCLUB事業を展開する TryHardがデビューとサポートを約束</p>
      <div className="pr">
        <img src={imgPr1} alt="関西最安値" />
        <img src={imgPr2} alt="生徒実績" />
        <img src={imgPr3} alt="確実なサポート" />
      </div>
    </Header>
  )
}

export default CompContentHomeHeader

const Header = styled.header`
  padding: 2rem 0;
  background: url(${imgBackground}) no-repeat center/cover;

  h1 {
    width: calc(100% - 3rem);
    width: 50%;
    max-width: 391px;
    margin: 0 auto;
    background: url(${imgLogo}) no-repeat center/contain;

    &::before {
      content: "";
      display: block;
      padding-top: 87.4680307%;
    }

    span {
      ${hideVisually()}
    }
  }

  p {
    width: calc(100% - 3rem);
    margin: 1rem auto;
    text-align: center;
    color: white;
    font-size: 1.5rem;
    font-weight: 900;
    text-shadow: 0 0 0.5em black, 0 0 0.25em black, 0 0 0.1em black;
  }

  .pr {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: calc(100% - 1rem);
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 0 0 0.5rem;

    img {
      max-width: calc(100% / 3);
      margin: 0 0 -1rem 0;
    }
  }
`
