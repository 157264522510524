import React from "react"
import styled from "styled-components"
import imgThreeFaiths from "../../../images/three-faiths.jpg"
import BtnMore from "../../btnMore"

const CompContentThreeFaiths = () => {
  return (
    <ThreeFaiths>
      <img src={imgThreeFaiths} alt="III Faiths DJ SCHOOL" />
      <div className="content">
        <h3>
          <span>III FAITHS DJ SCHOOL</span>
        </h3>
        <p>
          III FAITHS DJ
          SCHOOLは、2020年で11年目を迎える日本で最も多くの人にDJを伝えたDJスクールであり、
          日本で唯一のグローバルな視野を打ち立てたDJスクールです。海外校を含む全8都市（渋谷、福岡、群馬、千葉、
          岡山、石川、仙台、フィリピン・セブ島）に展開し、登録者数3,500人以上（*2020年8月現在）、輩出実績は
          東京全域のCLUB・PARTYシーン、地方都市を飛び越え「アメリカ」「オーストラリア」「スウェーデン」「シンガポール」「フィリピン」「ベトナム」
          「台湾
          」「中国」「カナダ」「ロシア」と、世界で活動する生徒を生み出している日本一のDJスクールです。2020年7月よりTryHard
          DJ Academyと正式に業務提携を結びⅢ FAITHS DJ
          SCHOOL代表の神山清豪が当アカデミーの総合プロデューサーに就任。全カリキュラム、教育システム、運営システムを導入しています。
        </p>
        <div className="more">
          <BtnMore
            link="https://3faithsdjschool.com/"
            external={true}
            style={{ margin: "0 0 0 auto" }}
          >
            詳しくみる
          </BtnMore>
        </div>
      </div>
    </ThreeFaiths>
  )
}

export default CompContentThreeFaiths

const ThreeFaiths = styled.div`
  margin: 3rem 0;

  img {
    max-width: 100%;
  }

  .content {
    padding: 1rem 1.5rem;
    background: #f2f2f2;
  }

  h3 {
    margin: 0%;

    span {
      display: inline-block;
      padding: 0.1em 1em;
      background: ${({ theme }) => theme.yellow};
    }
  }

  p {
    margin: 1rem 0 0;
    line-height: 1.75;
  }

  .more {
    margin: 1rem 0 0;
  }
`
