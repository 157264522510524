import React from "react"
import UnstyledImg from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"
import BtnMore from "../../btnMore"

const CompContentDj1 = () => {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "dj-1-banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgDj1: file(relativePath: { eq: "dj-1-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgDj2: file(relativePath: { eq: "dj-1-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgDj3: file(relativePath: { eq: "dj-1-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgDj4: file(relativePath: { eq: "dj-1-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgDj5: file(relativePath: { eq: "dj-1-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgDj6: file(relativePath: { eq: "dj-1-6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgDj7: file(relativePath: { eq: "dj-1-7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgDj8: file(relativePath: { eq: "dj-1-8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgDj9: file(relativePath: { eq: "dj-1-9.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgDj10: file(relativePath: { eq: "dj-1-10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <DJ1>
      <h2>卒業生参加イベント</h2>
      <UnstyledImg fluid={data.banner.childImageSharp.fluid} />
      <h3>日本から世界へ！目指せ次世代No.1 DJ</h3>
      <p>
        DJ-1 プロジェクト始動。未来を代表するDJ達が成長しながら活躍できる新しい企画。<br />
        夢を持ち共に切磋琢磨し 次世代日本No.1 DJを目指していくプロジェクト。当アカデミーは公式スポンサーとして<br />
        DJ-1に出演する全てのアーティストを応援しています。
      </p>
      <div class="djs">
        <ImgDj fluid={data.imgDj1.childImageSharp.fluid} alt="" />
        <ImgDj fluid={data.imgDj2.childImageSharp.fluid} alt="" />
        <ImgDj fluid={data.imgDj3.childImageSharp.fluid} alt="" />
        <ImgDj fluid={data.imgDj4.childImageSharp.fluid} alt="" />
        <ImgDj fluid={data.imgDj5.childImageSharp.fluid} alt="" />
        <ImgDj fluid={data.imgDj6.childImageSharp.fluid} alt="" />
        <ImgDj fluid={data.imgDj7.childImageSharp.fluid} alt="" />
        <ImgDj fluid={data.imgDj8.childImageSharp.fluid} alt="" />
        <ImgDj fluid={data.imgDj9.childImageSharp.fluid} alt="" />
        <ImgDj fluid={data.imgDj10.childImageSharp.fluid} alt="" />
      </div>
      <BtnMore link="https://www.dj-1.jp" external={true} style={{margin: "2rem auto"}}>公式ホームページへ</BtnMore>
    </DJ1>
  )
}

export default CompContentDj1

const DJ1 = styled.div`
  margin: 2rem auto;
  padding: 2rem 0;
  width: calc(100% - 1rem);
  max-width: 1000px;
  text-align: center;

  .djs {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1rem;

    @media (max-width: 600px) {
      grid-gap: 0.5rem;
    }
  }

  p {
    text-align: center;

    @media (max-width: 640px) {
      text-align: left;
    }
  }
`

const ImgDj = styled(UnstyledImg)``;