import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Renderer from "../../renderer"
import BtnMore from "../../btnMore"

const CompContentTestimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      students: allMdx(
        filter: {
          fields: { sourceInstanceName: { eq: "students" } }
          frontmatter: { showOnTop: { eq: true } }
        }
        sort: { fields: frontmatter___sortTop, order: ASC }
      ) {
        edges {
          node {
            id
            slug
            body
            frontmatter {
              name
              photo {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const students = data.students.edges.map(i => (
    <li key={i.node.id} className="student">
      <div className="student__header">
        <div className="student__photo">
          <Img fluid={i.node.frontmatter.photo.childImageSharp.fluid} />
        </div>
        <div className="student__name">{i.node.frontmatter.name}</div>
      </div>
      <div className="student__body">
        <Renderer>{i.node.body}</Renderer>
      </div>
    </li>
  ))

  return (
    <Testimonials>
      <p className="lead">
        きっかけは様々でもDJとしての夢を追い続ける1つのステップとして
        <br />
        TryHard DJ Academyで学んで来た生徒様の生の声をお届けします。
      </p>
      <ul>{students}</ul>
      <BtnMore link="/students" style={{ margin: "0 auto 2rem" }}>
        卒業生紹介ページへ
      </BtnMore>
    </Testimonials>
  )
}

export default CompContentTestimonials

const Testimonials = styled.div`
  .lead {
    margin: 2rem 0;
    font-size: 1.25rem;
    font-weight: 500;
    text-align: center;
  }

  ul {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .student {
    width: calc((100% - 3rem) / 3);
    margin: 0 0 2rem;

    @media (max-width: 768px) {
      width: calc(50% - 0.75rem);
    }

    @media (max-width: 600px) {
      width: calc(50% - 0.5rem);
    }

    @media (max-width: 500px) {
      width: calc(50% - 0.25rem);
    }
  }

  .student__header {
    position: relative;
  }

  .student__name {
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    padding: 0.5rem 1.5rem;
    background: ${({ theme }) => theme.yellow};
    color: #222;
    font-size: 1.25rem;
    font-weight: 700;

    @media (max-width: 375px) {
      padding: 0.25rem 1rem;
      font-size: 1rem;
    }
  }

  .student__body {
    text-align: center;
    font-size: 0.875rem;
  }
`
