import React from "react"
import UnstyledImg from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"
import BtnMore from "../../btnMore"

const CompContentInstructors = () => {
  const data = useStaticQuery(graphql`
    query {
      instructors: allMdx(
        filter: { fields: { sourceInstanceName: { eq: "instructors" } } }
        sort: { fields: frontmatter___sort, order: ASC }
      ) {
        edges {
          node {
            id
            slug
            frontmatter {
              name
              photo {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const instructors = data.instructors.edges.map(i => {
    return (
      <li key={i.node.id}>
        <Link to={i.node.slug} className="link">
          <Img fluid={i.node.frontmatter.photo.childImageSharp.fluid} />
          <span
            className={[
              "name",
              i.node.frontmatter.name.length > 18 ? "name-long" : "",
            ].join(" ")}
          >
            {i.node.frontmatter.name}
          </span>
        </Link>
      </li>
    )
  })

  return (
    <Instructors>
      <h2>経験豊富な講師陣</h2>
      <ul>
        {instructors}
        <li>
          <BtnMore link="/instructors">講師紹介ページへ</BtnMore>
        </li>
      </ul>
    </Instructors>
  )
}

export default CompContentInstructors

const Instructors = styled.div`
  margin: 2rem auto;
  padding: 2rem 0;
  background: repeating-linear-gradient(
    135deg,
    white 0px,
    white 2px,
    #e7e7e7 3px,
    #e7e7e7 5px,
    white 6px
  );

  ul {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: calc(100% - 1rem);
    max-width: 1000px;
    margin: 0 auto;
    padding: 0;
    list-style: none;
  }

  li {
    width: calc((100% - 6rem) / 5);
    margin: 0 1.5rem 1.5rem 0;

    &:nth-child(5n) {
      margin: 0 0 1.5rem 0;
    }

    @media (max-width: 768px) {
      width: calc((100% - 4.5rem) / 4);

      &:nth-child(5n) {
        margin: 0 1.5rem 1.5rem 0;
      }

      &:nth-child(4n) {
        margin: 0 0 1.5rem 0;
      }
    }

    @media (max-width: 600px) {
      width: calc((100% - 2rem) / 3);
      margin: 0 1rem 1rem 0;

      &:nth-child(5n) {
        margin: 0 1rem 1rem 0;
      }

      &:nth-child(4n) {
        margin: 0 1rem 1rem 0;
      }

      &:nth-child(3n) {
        margin: 0 0 1rem 0;
      }
    }

    @media (max-width: 414px) {
      width: calc((100% - 1rem) / 2);
      margin: 0 1rem 1rem 0;

      &:nth-child(3n) {
        margin: 0 1rem 1rem 0;
      }

      &:nth-child(2n) {
        margin: 0 0 1rem 0;
      }
    }

    &:last-child {
      position: relative;
      align-self: stretch;
      width: calc((100% - 6rem) / 2.5 + 1.5rem);
      min-width: 250px;
      margin-right: 0;
      padding: 2rem 0;

      @media (max-width: 768px) {
        width: calc((100% - 4.5rem) / 4 * 3 + 3rem);
      }

      @media (max-width: 600px) {
        width: calc((100% - 2rem) / 1.5 + 1rem);
      }

      a {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }

      @media (max-width: 414px) {
        width: 100%;
        a {
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .link {
    position: relative;
    display: block;
    color: black;
    text-decoration: none;
  }

  .name {
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0.25rem;
    text-align: center;
    font-size: 0.875rem;
    background: rgba(255, 255, 255, 0.7);

    &.name-long {
      font-size: 0.75rem;
    }
  }
`

const Img = styled(UnstyledImg)`
  position: relative;
  z-index: 1;
`
