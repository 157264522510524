import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import UnstyledImg from "gatsby-image"
import imgBackground from "../../../images/bg-brick-min.jpg"

const CompContentComparisonTable = () => {
  const data = useStaticQuery(graphql`
    query {
      tableDesktop: file(relativePath: { eq: "comparison-table.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tableMobile: file(relativePath: { eq: "comparison-table-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <ComparisonTable>
      <div className="inner">
        <h2>
          比べて納得！
          <br />
          TryHard DJアカデミー
        </h2>
        <ImgPc fluid={data.tableDesktop.childImageSharp.fluid} alt="" />
        <ImgMobile fluid={data.tableMobile.childImageSharp.fluid} alt="" />
      </div>
    </ComparisonTable>
  )
}

export default CompContentComparisonTable

const ComparisonTable = styled.div`
  padding: 2rem 0;
  background: url(${imgBackground}) no-repeat center/cover;
  text-align: center;

  .inner {
    width: calc(100% - 1rem);
    max-width: 1000px;
    margin: 0 auto;
  }

  h2 {
    margin: 0 0 2rem;
  }

  img {
    max-width: 100%;
  }
`

const ImgPc = styled(UnstyledImg)`
  @media (max-width: 768px) {
    display: none;
  }
`
const ImgMobile = styled(UnstyledImg)`
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`
