import React from "react"
import styled from "styled-components"

const CompContentPlans = ({ showAdvanced = true }) => {
  return (
    <Plans className={showAdvanced ? "" : "no-advanced"}>
      <div className="plan plan-1">
        <h3 className="title">前払いキャッシュプラン</h3>
        <div className="fullprice">
          182,400<span className="yen">円</span>/48時間
        </div>
        <div className="hour">１時間あたり</div>
        <div className="hourprice">
          <span className="tuition">受講料</span>
          <span className="hourprice-actual">
            <span className="yen">¥</span>
            3,800
          </span>
        </div>
      </div>

      <div className="plan plan-2">
        <h3 className="title">前払い通常プラン</h3>
        <div className="fullprice">
          193,344<span className="yen">円</span>/48時間
        </div>
        <div className="hour">１時間あたり</div>
        <div className="hourprice">
          <span className="tuition">受講料</span>
          <span className="hourprice-actual">
            <span className="yen">¥</span>
            4,028
          </span>
        </div>
      </div>

      <div className="plan plan-3">
        <h3 className="title">月謝払いプラン</h3>
        <div className="fullprice">
          37,440<span className="yen">円</span>/8時間
        </div>
        <div className="hour">１時間あたり</div>
        <div className="hourprice">
          <span className="tuition">受講料</span>
          <span className="hourprice-actual">
            <span className="yen">¥</span>
            4,680
          </span>
        </div>
      </div>

      {showAdvanced && (
        <div className="plan plan-4">
          <h3 className="title">アドバンスコース</h3>
          <div className="fullprice">更に高度な技術習得が可能なコース</div>
          <div className="hour">１時間あたり</div>
          <div className="hourprice">
            <span className="tuition">受講料</span>
            <span className="hourprice-actual">
              <span className="yen">¥</span>
              6,000
            </span>
          </div>
        </div>
      )}

      <ZeroNotice>
        <p>※DJ ZERO コースのみ料金プランが異なります。</p>
      </ZeroNotice>
    </Plans>
  )
}

export default CompContentPlans

const Plans = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;

  .plan-1 {
    --color: #e60039;
  }
  .plan-2 {
    --color: #ffcd00;
  }
  .plan-3 {
    --color: #18ab00;
  }
  .plan-4 {
    --color: #969696;
  }

  .plan {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: calc(50% - 0.5rem);
    margin: 0 0 1rem 0;
    padding: 1rem 0.5rem;
    border: 0.5rem solid var(--color);
    background: #f1f1f1;
    text-align: center;
    font-size: 1rem;

    @media (max-width: 900px) {
      font-size: 0.875rem;
    }

    @media (max-width: 768px) {
      font-size: 0.75rem;
    }

    @media (max-width: 600px) {
      width: calc(50% - 0.25rem);
      margin: 0 0 0.5rem 0;
      border: 0.25rem solid var(--color);
      font-size: 0.66rem;
    }

    @media (max-width: 540px) {
      padding: 1rem 0.25rem;
      font-size: 0.5rem;
    }
  }

  .title {
    /* margin: 0 0 0.5em; */
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 1.875em;
    font-weight: 900;
  }

  .fullprice {
    margin: 0.5em 0;
    font-size: 2.25em;
    font-weight: 500;
    line-height: 1;
  }

  .plan-4 .fullprice {
    margin: 0.75em 0;
    font-size: 1.5em;
    line-height: 1.5;
  }

  .yen {
    font-size: 0.67em;
  }

  .hour {
    display: inline-block;
    margin: 0.5rem 0 0;
    padding: 0.25em 0.5em;
    border-radius: 0.25rem;
    background: var(--color);
    color: white;
    line-height: 1;
    font-size: 1.5em;
    font-weight: 900;
  }

  .hourprice {
  }

  .tuition {
    font-size: 1.875em;
    margin: 0 0.5em 0 0;
  }

  .hourprice-actual {
    font-size: 3.5em;
    font-weight: 900;
  }

  &.no-advanced {
    .plan {
      width: calc((100% - 2rem) / 3);
      font-size: 0.875rem;

      @media (max-width: 900px) {
        font-size: 0.75rem;
      }

      @media (max-width: 768px) {
        width: calc((100% - 1rem) / 3);
        margin: 0 0 0.5rem 0;
        border: 0.25rem solid var(--color);
        font-size: 0.67rem;
      }

      @media (max-width: 600px) {
        padding: 1rem 0.25rem;
        font-size: 0.5rem;
      }

      @media (max-width: 540px) {
        width: 100%;
      }
    }
  }
`

const ZeroNotice = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
`;