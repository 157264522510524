import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const CompBtnMore = ({
  children,
  link,
  color1 = "black",
  color2 = "white",
  external = false,
  ...props
}) => {
  return (
    <>
      {!external && (
        <Button to={link} {...props} color1={color1} color2={color2}>
          {children}
        </Button>
      )}
      {external && (
        <Button
          as="a"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          {...props}
          color1={color1}
          color2={color2}
        >
          {children}
        </Button>
      )}
    </>
  )
}

export default CompBtnMore

const Button = styled(Link)`
  --color1: ${({ color1 }) => color1};
  --color2: ${({ color2 }) => color2};

  position: relative;
  display: block;
  width: 250px;
  padding: 0.5em 1.5em 0.4em 1.5em;
  color: var(--color2);
  background: linear-gradient(to right, var(--color2) 0, var(--color2) 100%),
    var(--color1);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 0 100%;
  text-align: left;
  text-decoration: none;
  font-size: 0.875rem;
  transition: background-size 0.25s, color 0.25s;

  &:hover {
    background-size: 100% 100%;
    background-position: left;
    color: var(--color1);

    &::before {
      border-color: var(--color1);
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.25em;
    width: 0.67em;
    height: 0.67em;
    border-right: 2px solid var(--color2);
    border-bottom: 2px solid var(--color2);
    transform: translateY(-50%) rotate(-45deg);
    transition: border-color 0.25s;
  }
`
