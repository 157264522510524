import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const CompContentFreeLessonBanner = () => {
  return (
    <FreeLessonBanner>
      <p>＼　完　全　無　料　／</p>
      <Button to="lesson">無料体験レッスン</Button>
    </FreeLessonBanner>
  )
}

export default CompContentFreeLessonBanner

const FreeLessonBanner = styled.div`
  padding: 2rem 0;
  background: repeating-linear-gradient(
    135deg,
    #e60039 0,
    #e60039 5px,
    #d9001c 7px,
    #d9001c 12px,
    #e60039 12px
  );
  text-align: center;

  p {
    margin: 0 0 1rem;
    font-size: 0.875rem;
    font-family: sans-serif;
    color: white;
  }
`

const Button = styled(Link)`
  position: relative;
  display: inline-block;
  font-size: 1.5rem;
  padding: 0.75em 3em 0.75em 1.5em;
  line-height: 1;
  border: 2px solid white;
  border-radius: 2rem;
  background: ${({ theme }) => theme.red};
  color: white;
  text-decoration: none;
  font-style: italic;
  font-weight: 900;
  box-shadow: 0.25rem 0.5rem 0.5rem rgba(0, 0, 0, 0.33);
  transition: background 0.25s;

  &:hover {
    background: ${({ theme }) => theme.dark};
  }

  &::before {
    position: absolute;
    content: "";
    top: 50%;
    right: 1.5rem;
    width: 1rem;
    height: 1rem;
    border: 0;
    border-right: 3px solid white;
    border-bottom: 3px solid white;
    transform: translateY(-50%) rotate(-45deg);
  }
`
